import Button from "@components/Button"
import {
  Colors,
  Iframe,
  Text,
  TitleH1,
  TitleH2,
} from "@components/global.styles"
import * as React from "react"
import { Fragment } from "react"
import { Row } from "shards-react"
import { Containe, Content } from "./styles"

const About: React.FC = () => {
  return (
    <Fragment>
      <Containe>
        <Row>
          <Content>
            <TitleH2
              className="big-text"
              style={{ marginTop: "1rem", marginBottom: "1rem", opacity: 0.7 }}
              colors={Colors.green}
            >
              Ce este acest grup?
            </TitleH2>
            <Text className="small-text" colors={Colors.orange}>
              Grupul de marti seara este un grup care are ca scop studierea
              Planului de mantuire a lumii asa cum este el expus in Scripturi.
              Incepand de la Geneza si avansand prin cartile Bibliei, incercam
              sa descoperim care sunt acele lucruri pe care Dumnezeu le-a lasat
              pentru noi, si descoperindu-le, sa crestem spre desavarsirea
              noastra.
            </Text>
            <TitleH2
              className="big-text"
              style={{ marginTop: "1rem", marginBottom: "1rem", opacity: 0.7 }}
              colors={Colors.green}
            >
              Unde ne puteti gasi?
            </TitleH2>
            <Text className="small-text" colors={Colors.orange}>
              Intalnirile noastre se desfasoara in cadrul Bisericii Baptiste
              Manastur nr. 1, pe care o gasiti la adresa Strada Ospătăriei 10,
              Cluj-Napoca 400660, Romania
            </Text>
            <br />
            <Text className="small-text" colors={Colors.orange}>
              Intalnirile noastre incep la ora 18:00 la subsolul bisericii. Va
              asteptam!
              <br />
              ATENTIE! pe durata pandemiei s-ar putea ca intalnirile sa fie
              mutate in online, pe acest grup de Skype:{" "}
              <a href="https://join.skype.com/nhq4AgWYcO48">
                https://join.skype.com/nhq4AgWYcO48
              </a>
            </Text>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Iframe
                title="harta"
                id="gmap_canvas"
                src="https://maps.google.com/maps?q=Strada%20Osp%C4%83t%C4%83riei%2010,%20Cluj-Napoca%20400660,%20Romania&t=&z=17&ie=UTF8&iwloc=&output=embed"
                colors={Colors.darkBackground}
                style={{ height: "500px" }}
              />
            </div>
          </Content>
        </Row>
      </Containe>
    </Fragment>
  )
}

export default About
