import GlobalLayout from "@layouts/index"
import About from "@sections/About"
import * as React from "react"

const IndexPage = (props: any) => {
  return (
    <GlobalLayout {...props}>
      <About />
    </GlobalLayout>
  )
}

export default IndexPage
